import React, { useState } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import CTATile from '../components/CTA-tile'
import { isWarpC } from '../lib/helpers'
import HeroSliderBlock from '../components/hero-slider-block'
import ArtistsParallaxBlock from '../components/artists-parallax-block'
import SelectedProjectsBlock from '../components/selected-projects-block'
import WarpPArtistListBlock from '../components/warp-p-artist-list-block'
import WarpCArtistListBlock from '../components/warp-c-artist-list-block'
import TextColumnsBlock from '../components/text-columns-block'
import NewsTickerBlock from '../components/news-ticker-block'
import LaurelBlock from '../components/laurel-block'
import CustomColumnsBlock from '../components/custom-columns-block'
import MusicGridBlock from '../components/music-grid-block'
import LinksBlock from '../components/links-block'
import HeadlinedColumnBlock from '../components/headlined-column-block'
import LinksGridBlock from '../components/links-grid-block'
import HeadlinedStackingColumnBlock from '../components/headlined-stacking-column-block'
import SelectedArtistsBlock from '../components/selected-artists-block'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`

const Page = props => {
  const { data, errors } = props

  if (errors) {
    return <GraphQLErrorList errors={errors} />
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const page = data.page || data.route.page
  const content = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case 'heroSliderBlock':
          el = <HeroSliderBlock key={c._key} {...c} />
          break
        case 'selectedArtistsBlock':
          el = <SelectedArtistsBlock key={c._key} {...c} />
          break
        case 'artistsParallaxBlock':
          el = <ArtistsParallaxBlock key={c._key} {...c} />
          break
        case 'linksBlock':
          el = <LinksBlock key={c._key} {...c} />
          break
        case 'selectedProjectsBlock':
          el = <SelectedProjectsBlock key={`${c._key}`} {...c} />
          break
        case 'headlinedColumnBlock':
          el = <HeadlinedColumnBlock key={`${c._key}`} {...c} />
          break
        case 'artistsListBlock':
          if (isWarpC()) {
            el = <WarpCArtistListBlock key={c._key} {...c} />
          } else {
            el = <WarpPArtistListBlock key={c._key} {...c} />
          }
          break
        case 'textColumnsBlock':
          el = <TextColumnsBlock key={c._key} {...c} />
          break
        case 'customColumnsBlock':
          el = <CustomColumnsBlock key={c._key} {...c} />
          break
        case 'musicGridBlock':
          el = <MusicGridBlock key={c._key} {...c} />
          break
        case 'laurelBlock':
          el = <LaurelBlock key={c._key} {...c} />
          break
        case 'newsTickerBlock':
          el = <NewsTickerBlock key={c._key} {...c} />
          break
        case 'linksGridBlock':
          el = <LinksGridBlock key={c._key} {...c} pageId={page.id} />
          break
        case 'headlinedStackingColumnBlock':
          el = <HeadlinedStackingColumnBlock key={c._key} {...c} />
          break
        case 'CTATile':
          el = <CTATile key={c._key} {...c} />
          break
        default:
          el = <code>NOT FOUND {`"${el}"`}</code>
      }
      return el
    })

  const pageTitle = data.route && !data.route.useSiteTitle && page.title
  const { description, image } = site.openGraph
  const title = pageTitle ? pageTitle : site.title

  return (
    <>
      <SEO title={title} description={description} image={image} />
      {content}
    </>
  )
}

export default Page

import React from 'react'
import Slider from './slider'
import WarpLogo from './warp-logo'
import cx from 'classnames'

function NewsTickerBlock(props) {
  const { items } = props
  const filteredItems = items.filter(item => !item.hidden)
  const containerClasses = cx('laurel-block')

  return filteredItems.length > 0 ? (
    <Slider className={containerClasses}>
      <ul>
        {filteredItems.map((item, index) => (
          <li key={item._key} className="laurel-block__entry">
              {console.log(item)}
              <div className="projects-slider-block-item__small_title text-xs text-caps">{item.title}:</div>
              <div className="flex-column text-m-minus text-caps">
                <div className="projects-slider-block-item__dot-left">{item.firstLine}</div>
                <div className="projects-slider-block-item__dot-right is-italic">{item.secondLine}</div>
                {item.laurel && <div className="laurel-block__entry__laurel-wrapper"><img className="laurel-block__entry__laurel" src={item.laurel.image.asset.url} alt={item.title} width="100%" height="100%" /></div>}
              </div>
          </li>
        ))}
      </ul>
    </Slider>
  ) : (
    <div className="laurel-block placeholder">
      <WarpLogo color="white" />
    </div>
  )
}

export default NewsTickerBlock
